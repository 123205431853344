import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const ScienceExhibition2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Scienceclass6-8/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Scienceclass6-8/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Scienceclass6-8/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Scienceclass6-8/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Scienceclass6-8/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Scienceclass6-8/6.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1.5,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                SCIENCE EXHIBITION - WONDERS OF IMAGINATION
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6-8     Date: : 25 October 2024
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Knowledge is the torch which illuminates the world.”
                                    <br />

                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    A Science Exhibition- Wonders of Imagination was organised by NPS, Yeshwanthpur with great
                                    fervour on Friday 25 th October 2024. The event saw young enthusiastic participants of classes 6-
                                    8 putting up interactive exhibits of working models and the investigation-based projects.
                                    <br></br>
                                    The exhibition was inaugurated by the Principal, Ms Sheeja Manoj. The event was a testament
                                    to the students' creativity, scientific curiosity and collaborative learning among the youngsters.
                                    <br></br>
                                    It was an excellent platform for students to explore their scientific interests, build their
                                    presentation skills and inspire curiosity among their peers. It fostered a deeper appreciation
                                    for wonders of Science and the power of imagination. The effort of all the participants was
                                    laudable and praiseworthy.<br></br>

                                </Typography>

                            </Box>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Scienceclass6-8 blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ScienceExhibition2024;